// import axios from "axios";
// const API_URL = process.env.REACT_APP_API;

// const axiosInstance = axios.create({
//   baseURL: `${API_URL}`, // Replace with your API base URL
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Function to refresh token
// const refreshToken = async () => {
//   const storedSessionValue = sessionStorage.getItem("authState");
//   const { refresh, access } = JSON.parse(storedSessionValue);
//   const refreshToken = refresh;
//   const response = await axios.post(`${API_URL}VMS/refreshToken`, {
//     refresh_token: refreshToken,
//   });
//   const { access_token } = response.data;
//   // Retrieve the authState object from session storage
//   let authState = JSON.parse(sessionStorage.getItem("authState"));

//   // Update the access value
//   authState.access = access_token; // Replace with the new access token

//   // Convert the object back to a JSON string
//   const updatedAuthState = JSON.stringify(authState);

//   // Store the updated object back in session storage
//   sessionStorage.setItem("authState", updatedAuthState);
//   return access_token;
// };

// // Request interceptor to add access token to request headers
// axiosInstance.interceptors.request.use(
//   async (config) => {
// const storedSessionValue = sessionStorage.getItem("authState");
// const { refresh, access } = JSON.parse(storedSessionValue);
// if (access) {
//   config.headers.Authorization = `Bearer ${access}`;
// }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// // Response interceptor to handle token refresh
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       (error.response.status === 401 || error.response.status === 403) &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         const newAccessToken = await refreshToken();
//         axiosInstance.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
//         return axiosInstance(originalRequest);
//       } catch (refreshError) {
//         // Redirect to login if token refresh fails
//         sessionStorage.removeItem("authState");
//         sessionStorage.removeItem("prevPath");
//         // window.location.href = "/login";
//         return Promise.reject(refreshError);
//       }
//       // const newAccessToken = await refreshToken();
//       // axiosInstance.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
//       // return axiosInstance(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
const API_URL = process.env.REACT_APP_API;

const axiosInstance = axios.create({
  baseURL: `${API_URL}`, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to refresh the access token
const refreshAccessToken = async () => {
  const storedSessionValue = sessionStorage.getItem("authState");
  const { refresh, access } = JSON.parse(storedSessionValue);
  const refreshToken = refresh;
  const response = await axios.post(`${API_URL}VMS/refreshToken`, {
    refresh_token: refreshToken,
  });
  const { access_token } = response.data;
  // Retrieve the authState object from session storage
  let authState = JSON.parse(sessionStorage.getItem("authState"));

  // Update the access value
  authState.access = access_token; // Replace with the new access token

  // Convert the object back to a JSON string
  const updatedAuthState = JSON.stringify(authState);

  // Store the updated object back in session storage
  sessionStorage.setItem("authState", updatedAuthState);
  return access_token;
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const storedSessionValue = sessionStorage.getItem("authState");
    const { access } = JSON.parse(storedSessionValue);
    if (access) {
      config.headers.Authorization = `Bearer ${access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        // Handle logout if the refresh token is invalid
        localStorage.removeItem("authState");
        localStorage.removeItem("prevPath");
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

// import axios from "axios";

// const API_URL = process.env.REACT_APP_API;

// // Create an Axios instance
// const axiosInstance = axios.create({
//   baseURL: `${API_URL}`,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Function to refresh the access token
// const refreshToken = async () => {
//   try {
//     console.log("Attempting to refresh token...");
//     const storedSessionValue = sessionStorage.getItem("authState");
//     if (!storedSessionValue) {
//       console.error("No session data found");
//       throw new Error("No session data found");
//     }

//     const { refresh } = JSON.parse(storedSessionValue);
//     console.log("Using refresh token:", refresh);

//     const response = await axios.post(`${API_URL}VMS/refreshToken`, {
//       refresh_token: refresh,
//     });

//     const { access_token } = response.data;
//     console.log("New access token received:", access_token);

//     const authState = JSON.parse(sessionStorage.getItem("authState"));
//     authState.access = access_token;
//     sessionStorage.setItem("authState", JSON.stringify(authState));

//     console.log("Updated session authState:", authState);

//     return access_token;
//   } catch (error) {
//     console.error(
//       "Token refresh error:",
//       error.response?.data || error.message
//     );
//     throw error;
//   }
// };

// // Add a request interceptor
// axiosInstance.interceptors.request.use(
//   (config) => {
//     console.log("Request interceptor triggered...");
//     const storedSessionValue = sessionStorage.getItem("authState");
//     if (storedSessionValue) {
//       const { access } = JSON.parse(storedSessionValue);
//       if (access) {
//         config.headers.Authorization = `Bearer ${access}`;
//         console.log("Authorization header set:", config.headers.Authorization);
//       }
//     }
//     console.log("Request Config:", config);
//     return config;
//   },
//   (error) => {
//     console.error("Request interceptor error:", error);
//     return Promise.reject(error);
//   }
// );

// // Handle refreshing logic
// let isRefreshing = false;
// let refreshSubscribers = [];

// const onRefreshed = (newAccessToken) => {
//   refreshSubscribers.forEach((callback) => callback(newAccessToken));
//   refreshSubscribers = [];
// };

// // Add a response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => {
//     console.log("Response received:", response);
//     return response;
//   },
//   async (error) => {
//     console.error(
//       "Response error received:",
//       error.response?.data || error.message
//     );
//     const originalRequest = error.config;

//     if (
//       error.response &&
//       (error.response.status === 401 || error.response.status === 403) &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       console.log("403 error encountered, retrying with refreshed token...");

//       if (!isRefreshing) {
//         isRefreshing = true;
//         try {
//           const newAccessToken = await refreshToken();
//           isRefreshing = false;
//           onRefreshed(newAccessToken);
//         } catch (refreshError) {
//           console.error(
//             "Error during token refresh:",
//             refreshError.response?.data || refreshError.message
//           );
//           isRefreshing = false;
//           refreshSubscribers = [];
//           sessionStorage.removeItem("authState");
//           return Promise.reject(refreshError);
//         }
//       }

//       return new Promise((resolve) => {
//         refreshSubscribers.push((newAccessToken) => {
//           originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//           console.log(
//             "Retrying original request with new token:",
//             originalRequest
//           );
//           resolve(axiosInstance(originalRequest));
//         });
//       });
//     }

//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;
