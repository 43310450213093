import React, { useEffect, useRef, useState } from "react";
import { toastDisplayer } from "../../components/toastDisplayer/toastdisplayer";
import { Button, Popup } from "devextreme-react";
import { useNavigate } from "react-router-dom";
import { PopupHeaderText } from "../../components/typographyText/TypograghyText";
import "./camera.scss";

const CameraPopup = ({ isVisible, onHide, setFormData }) => {
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [imageSrcBase, setImageSrcBase] = useState(null);
  const [isCameraAvailable, setIsCameraAvailable] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  // const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoDevices = devices.filter(
            (device) => device.kind === "videoinput"
          );
          setIsCameraAvailable(videoDevices.length > 0);
        })
        .catch((err) => {
          console.error("Error checking camera availability: ", err);
        });
    } else {
      console.error("enumerateDevices is not supported");
      toastDisplayer("error", "Camera not supported due to HTTP site");
    }
  }, []);

  const handleNoCamera = () => {
    return navigate("/");
  };
  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
        })
        .catch((err) => {
          console.error("Error accessing the camera: ", err);
        });
    } else {
      console.error("getUserMedia is not supported");
      toastDisplayer("error", "getUserMedia is not supported");
    }
  };

  const stopCamera = () => {
    let stream = videoRef.current.srcObject;
    let tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    videoRef.current.srcObject = null;
  };

  const toggleCamera = () => {
    if (isCameraOn) {
      stopCamera();
    } else {
      startCamera();
    }
    setIsCameraOn((prevState) => !prevState);
  };

  // Capture Photo
  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageSrc = canvas.toDataURL("image/jpeg");
    setImageSrcBase(imageSrc);
    stopCamera();
    setIsCameraOn(false);
  };

  const handleActionContinue = () => {
    setFormData((prev) => ({
      ...prev,
      vavatar: imageSrcBase,
    }));
    handleClosePopup();
  };

  const handleClosePopup = () => {
    onHide();
    // stopCamera();
    setIsCameraOn(false);
  };

  return (
    <>
      <Popup
        visible={isVisible}
        onHiding={handleClosePopup}
        height={600}
        width={"80vw"}
        showCloseButton={false}
        dragEnabled={false}
        showTitle={false}
      >
        <div className="verification-popup-main">
          <PopupHeaderText text={"Click the visitor photo"} />
          <div className="popup-close-btn">
            <Button icon="close" onClick={handleClosePopup} />
          </div>
        </div>
        <div className={`picture ${isCameraOn ? "fullscreen" : ""}`}>
          {isCameraOn && (
            <div className="imgcapture">
              <div className="clickPicture">
                <video
                  ref={videoRef}
                  autoPlay
                  style={{
                    display: isCameraOn ? "block" : "none",
                    marginTop: "2rem",
                  }}
                ></video>
                {/* {isCameraOn && (
                                    <Button text="Click Picture" onClick={capturePhoto} stylingMode="outlined" className='clickPicture' />
                                )} */}
                {isCameraOn && (
                  <div className="photo-button1" onClick={capturePhoto}>
                    <div className="circle"></div>
                    <div className="ring"></div>
                  </div>
                )}
              </div>
              <canvas
                ref={canvasRef}
                // width="720"
                // height="300"
                style={{ display: "none" }}
              ></canvas>
            </div>
          )}

          {!isCameraOn && imageSrcBase && (
            <div className="imgcapture">
              <img src={imageSrcBase} alt="profile" />
            </div>
          )}
          {!isCameraOn && (
            <div className="captureBtn">
              {isCameraAvailable ? (
                <Button
                  text={"Click a picture"}
                  // width={"50%"}
                  height={"44px"}
                  className="clickBtn"
                  onClick={toggleCamera}
                />
              ) : (
                <>
                  <p className="no-camera">* Camera not available</p>
                  <Button width={150} onClick={onHide} text="Close" />
                </>
              )}

              {imageSrcBase && (
                <Button
                  text={"Continue"}
                  // width={"50%"}
                  height={"44px"}
                  onClick={handleActionContinue}
                />
              )}
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};

export default CameraPopup;
